import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    filterProducts();
  }, [searchTerm, products]);

  const fetchProducts = () => {
    axios.get('https://phplaravel-1325195-4846320.cloudwaysapps.com/api/product')
      .then(response => {
        const data = Array.isArray(response.data) ? response.data : [];
        setProducts(data);
        setFilteredProducts(data);
      })
      .catch(error => {
        console.error('Error fetching products:', error);
        setMessage('Error fetching products. Please try again later.');
        setProducts([]);
        setFilteredProducts([]);
      });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filterProducts = () => {
    const term = searchTerm.toLowerCase();
    const filtered = products.filter(product =>
      product.name.toLowerCase().includes(term) ||
      product.description.toLowerCase().includes(term)
    );
    setFilteredProducts(filtered);
  };

  return (
    <div className="container bg-dark text-white py-5">
      <h1 className="text-center mb-5">Our Coffee Selection</h1>
      {message && <div className="alert alert-info">{message}</div>}

      <div className="mb-4 d-flex justify-content-center">
        <div className="input-group w-50">
          <input
            type="text"
            placeholder="Search for your favorite coffee..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="form-control rounded-pill border-0 shadow-sm text-center"
          />
         
        </div>
      </div>

      <div className="row">
        {filteredProducts.length > 0 ? (
          filteredProducts.map(product => (
            <div key={product.id} className="col-md-4 mb-4">
              <div className="card bg-dark text-white border-light shadow-sm">
                <img
                  src={`https://phplaravel-1325195-4846320.cloudwaysapps.com/storage/pictures2/${product.image}`}
                  alt={product.name}
                  className="card-img-top"
                  style={{ height: '200px', objectFit: 'cover' }}
                />
                <div className="card-body">
                  <h5 className="card-title">{product.name}</h5>
                  <p className="card-text">{product.description}</p>
                  <p className="card-text"><strong>Price: </strong>₱{product.price}</p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No products available.</p>
        )}
      </div>
    </div>
  );
};

export default Products;

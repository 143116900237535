import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import heroImage from './images/pp6_hero.jpg';
import Options from '../Utils/Options';

function Login({ setUser }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  // Check if the user is already logged in
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      // Redirect to dashboard if already logged in
      navigate('/dashboard');
    }
  }, [navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();

      // `${Options.host}/api/login`,
    axios.post(
      'https://phplaravel-1325195-4846320.cloudwaysapps.com/api/login',
      { email, password },
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    )
    .then((response) => {
      const data = response.data;

      if (data.success) {
        // Save token and user data to local storage
        localStorage.setItem('token', data.token);
        localStorage.setItem('user', JSON.stringify(data.user));
        setUser(data.user); // Update user state
        setMessage('Login successful! Redirecting...');
        
        // Redirect to the dashboard after a short delay
        setTimeout(() => navigate('/dashboard'), 1000);
      } else {
        setMessage(data.message || 'Login failed. Please check your credentials.');
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      setMessage(`An error occurred: ${error.response?.data?.message || error.message}`);
    });
  };

  // Internal CSS
  const styles = {
    container: {
      marginTop: '5rem',
    },
    row: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    heroImage: {
      img: {
        width: '100%',
        borderRadius: '.25rem',
        boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15)',
      },
    },
    formContainer: {
      backgroundColor: '#343a40',
      padding: '1.5rem',
      borderRadius: '.25rem',
      boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15)',
      color: 'white',
    },
    formGroup: {
      marginBottom: '1rem',
    },
    formControl: {
      width: '100%',
      padding: '.5rem',
      borderRadius: '.25rem',
    },
    btnLight: {
      backgroundColor: 'white',
      color: 'black',
      marginTop: '1rem',
    },
    message: {
      marginTop: '0.5rem',
    },
    link: {
      color: '#17a2b8',
      textDecoration: 'none',
    },
    button: {
      backgroundColor: '#f8f9fa',
      color: '#343a40',
      marginTop: '1rem',
    },
    link: {
      color: '#17a2b8',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.row}>
        {/* Hero Image Column */}
        <div style={{ flex: '0 0 66.6667%' }}>
          <div style={styles.heroImage}>
            <img
              src={heroImage}
              style={styles.heroImage.img}
              alt="Hero Image"
            />
          </div>
        </div>

        {/* Login Form Column */}
        <div style={{ flex: '0 0 33.3333%' }}>
          <h1 class="ps-3" style={{ color: 'white' }}>Login</h1>
          <form onSubmit={handleSubmit} style={styles.formContainer}>
            <div style={styles.formGroup}>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                style={styles.formControl}
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div style={styles.formGroup}>
              <label htmlFor="password">Password</label>
              <input
                type="password"
                style={styles.formControl}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn" style={styles.button}>Login</button>
            {message && <p style={styles.message}>{message}</p>}
          </form>

        </div>
      </div>
    </div>
  );
}

export default Login;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ProductList = () => {
  const [products, setProducts] = useState([]); // State to store products
  const [message, setMessage] = useState(''); // State to store messages
  const [errors, setErrors] = useState({}); // State to store validation errors
  const navigate = useNavigate(); // Hook to navigate between routes

  useEffect(() => {
    fetchProducts(); // Fetch products when the component mounts
  }, []);

  // Function to fetch products from the API
  const fetchProducts = () => {
    axios.get('https://phplaravel-1325195-4846320.cloudwaysapps.com/api/product')
      .then(response => {
        const data = Array.isArray(response.data) ? response.data : [];
        setProducts(data); // Update state with the fetched products
      })
      .catch(error => {
        console.error('Error fetching products:', error);
        setMessage('Error fetching products. Please try again later.');
        setProducts([]); // Reset products in case of an error
      });
  };

  // Function to validate product fields before performing any actions
  const validateProduct = (product) => {
    const errors = {};

    // Validate product name
    if (!product.name || typeof product.name !== 'string' || product.name.length > 255) {
      errors.name = 'Product name is required and should be a string with a maximum of 255 characters.';
    }

    // Validate product description
    if (product.description && typeof product.description !== 'string') {
      errors.description = 'Product description should be a string.';
    }

    // Validate product price
    if (!product.price || isNaN(product.price)) {
      errors.price = 'Product price is required and should be a valid number.';
    }

    // Validate product image
    if (product.image && (!['image/jpeg', 'image/png', 'image/jpg', 'image/gif'].includes(product.image.type) || product.image.size > 2048 * 1024)) {
      errors.image = 'Product image should be a valid image file (jpeg, png, jpg, gif) and less than 2MB.';
    }

    return errors;
  };

  // Function to handle editing a product
  const handleEdit = (productId) => {
    if (productId) {
      navigate(`/editProduct/${productId}`); // Navigate to the edit product page
    } else {
      setMessage('Product ID is missing.');
    }
  };

  // Function to handle deleting a product
  const handleDelete = (productId) => {
    axios.delete(`https://phplaravel-1325195-4846320.cloudwaysapps.com/api/product/${productId}/destroy`)
      .then(response => {
        if (response.status === 204) {
          setMessage('Product deleted successfully!');
          fetchProducts(); // Refresh the product list after deletion
        } else {
          setMessage('Failed to delete product.');
        }
      })
      .catch(error => {
        console.error('Error deleting product:', error);
        setMessage('An error occurred while deleting the product. Please try again later.');
      });
  };

  return (
    <div className="container">
      <style>
        {`
          .bg-dark {
            background-color: #343a40; /* Dark background for the container */
          }
          .text-white {
            color: #ffffff; /* White text color */
          }
          .py-5 {
            padding-top: 3rem;
            padding-bottom: 3rem;
          }
          .text-center {
            text-align: center; /* Center align text */
          }
          .mb-5 {
            margin-bottom: 3rem; /* Bottom margin */
          }
          .alert-info {
            background-color: #17a2b8; /* Info alert background */
            color: white;
          }
          .alert-danger {
            background-color: #dc3545; /* Danger alert background */
            color: white;
          }
          .row {
            display: flex;
            flex-wrap: wrap; /* Ensure proper wrapping of child elements */
          }
          .col-md-4 {
            flex: 0 0 33.333333%; /* Make each column take up one-third of the row */
            max-width: 33.333333%; /* Set maximum width to one-third of the container */
          }
          .mb-4 {
            margin-bottom: 1.5rem; /* Bottom margin for spacing */
          }
          .card {
            border: 1px solid #343a40; /* Border color matching the dark theme */
          }
          .card-img-top {
            height: 200px; /* Fixed height for product images */
            object-fit: cover; /* Ensure image covers the entire area */
          }
          .btn-outline-light {
            color: #f8f9fa; /* Light text color for buttons */
            border-color: #f8f9fa; /* Light border color for buttons */
          }
          .btn-outline-light:hover {
            background-color: #f8f9fa; /* Light background color on hover */
            color: #343a40; /* Dark text color on hover */
          }
          .btn-outline-danger {
            color: #dc3545; /* Red text color for delete button */
            border-color: #dc3545; /* Red border color for delete button */
          }
          .btn-outline-danger:hover {
            background-color: #dc3545; /* Red background color on hover */
            color: #ffffff; /* White text color on hover */
          }
          .d-flex {
            display: flex;
            justify-content: space-between; /* Space out buttons within card */
          }
        `}
      </style>

      <h1 className="text-center text-white mt-5 mb-5">Our Coffee Selection</h1>
      {message && <div className="alert alert-info">{message}</div>}
      {errors && Object.keys(errors).map((key) => (
        <div key={key} className="alert alert-danger">
          {errors[key]}
        </div>
      ))}
      <div className="row">
        {products.length > 0 ? (
          products.map(product => (
            <div key={product.id} className="col-md-4 mb-4">
              <div className="card bg-dark text-white border-light shadow-sm">
                <img
                  src={`https://phplaravel-1325195-4846320.cloudwaysapps.com/storage/pictures2/${product.image}`} // Updated path to match the storage directory
                  alt={product.name}
                  className="card-img-top"
                  style={{ height: '200px', objectFit: 'cover' }}
                />
                <div className="card-body">
                  <h5 className="card-title">{product.name}</h5>
                  <p className="card-text">{product.description}</p>
                  <p className="card-text"><strong>Price: </strong>₱{product.price}</p>
                  <div className="d-flex justify-content-between">
                    <button
                      className="btn btn-outline-light"
                      onClick={() => handleEdit(product.id)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-outline-danger"
                      onClick={() => handleDelete(product.id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No products available.</p>
        )}
      </div>
    </div>
  );
};

export default ProductList;

import React, { useState } from 'react'; // Import React and useState hook for managing component state
import { useNavigate } from 'react-router-dom'; // Import useNavigate for programmatic navigation
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap for styling
import './CreateProduct.css'; // Import custom CSS for additional styling
import heroImage from './images/pp6_hero.jpg'; // Import the hero image to be used in the component

const CreateProduct = () => {
  // Declare state variables for form fields and UI management
  const [name, setName] = useState(''); // State for product name
  const [description, setDescription] = useState(''); // State for product description
  const [price, setPrice] = useState(''); // State for product price
  const [image, setImage] = useState(null); // State for the selected product image file
  const [imageName, setImageName] = useState(''); // State for displaying the selected image file name
  const [message, setMessage] = useState(''); // State for managing success/error messages
  const [showModal, setShowModal] = useState(false); // State for controlling the display of the modal
  const [isSuccess, setIsSuccess] = useState(false); // State to track if the operation was successful or not
  const navigate = useNavigate(); // Initialize useNavigate to enable navigation between routes

  // Function to handle image file selection
  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Get the first selected file
    setImage(file); // Update the state with the selected file
    setImageName(file ? file.name : ''); // Set the file name or reset if no file is selected
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Create a FormData object to send form data including files
    const formData = new FormData();
    formData.append('name', name); // Append product name to formData
    formData.append('description', description); // Append product description to formData
    formData.append('price', price); // Append product price to formData
    if (image) {
      formData.append('image', image); // Append product image file to formData if selected
    }

    // Send the form data to the backend API using fetch
    fetch('https://phplaravel-1325195-4846320.cloudwaysapps.com/api/product', {
      method: 'POST',
      body: formData, // Set formData as the request body
      headers: {
        'Accept': 'application/json', // Specify the response format expected from the server
        // Note: No need to set Content-Type for FormData, the browser handles it automatically
      },
    })
      .then(response => {
        if (!response.ok) {
          // If the response status is not OK, throw an error
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json(); // Parse the response data as JSON
      })
      .then(data => {
        if (data) {
          // If data is returned, assume product creation was successful
          setMessage('Product created successfully!'); // Set success message
          setIsSuccess(true); // Indicate success
          setShowModal(true); // Show the modal
          // Reset the form fields after successful submission
          setName('');
          setDescription('');
          setPrice('');
          setImage(null);
          setImageName('');
          // Redirect to the dashboard after showing the modal for 2 seconds
          setTimeout(() => navigate('/dashboard'), 2000);
        } else {
          // If no data is returned, indicate failure
          setMessage('Failed to create product.');
          setIsSuccess(false);
          setShowModal(true); // Show the modal
        }
      })
      .catch(error => {
        // Handle any errors that occur during the fetch process
        console.error('Error:', error);
        setMessage('An error occurred. Please try again.'); // Set error message
        setIsSuccess(false); // Indicate failure
        setShowModal(true); // Show the modal
      });
  };

  return (
    <div className="container mt-5">
      <h4 className="text-light mb-4">Create Product || 
        <a href="/dashboard"> 
        Back to Dashboard
        </a>
      </h4>
      
      {/* New Row and Columns */}
      <div className="row">
        <div className="col-sm-8">
          {/* Hero Image Section */}
          <div className="hero-image mb-4">
            <img
              src={heroImage}  // Source of the hero image to be displayed
              className="img-fluid rounded shadow" // Apply Bootstrap classes for styling
              alt="Hero Image" // Alternative text for the image
            />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-section">
            {/* Form Section */}
            <form onSubmit={handleSubmit} className="bg-dark p-4 rounded shadow">
              <div className="form-group">
                <label htmlFor="name" className="text-light">Product Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)} // Update name state on input change
                  required // Make the input field required
                />
              </div>
              <div className="form-group">
                <label htmlFor="description" className="text-light">Description</label>
                <textarea
                  className="form-control"
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)} // Update description state on input change
                  required // Make the textarea required
                />
              </div>
              <div className="form-group">
                <label htmlFor="price" className="text-light">Price</label>
                <input
                  type="number"
                  className="form-control"
                  id="price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)} // Update price state on input change
                  required // Make the input field required
                />
              </div>
              <div className="form-group">
                <label htmlFor="image" className="text-light">Product Image</label>
                <input
                  type="file"
                  className="form-control-file"
                  id="image"
                  onChange={handleImageChange} // Handle file selection
                  required // Make the file input required
                />
                {imageName && <p className="mt-2 text-light">Selected file: {imageName}</p>} {/* Display selected file name */}
              </div>
              <button type="submit" className="btn btn-light mt-3">Create Product</button> {/* Submit button */}
            </form>
          </div>
        </div>
      </div>

      {/* Modal */}
      <div className={`modal fade ${showModal ? 'show d-block' : ''}`} tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content bg-dark text-light rounded-0">
            <div className="modal-header">
              <h5 className="modal-title">{isSuccess ? 'Success' : 'Error'}</h5> {/* Display success or error title */}
              <button type="button" className="close" onClick={() => setShowModal(false)}>
                <span>&times;</span> {/* Close button */}
              </button>
            </div>
            <div className="modal-body">
              <p>{message}</p> {/* Display the success or error message */}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-light" onClick={() => setShowModal(false)}>Close</button> {/* Close modal button */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProduct; // Export the component

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ProductList from './ProductList';
import heroImage from './images/pp6_hero.jpg'; // Importing the hero image

// Define the Dashboard component
function Dashboard() {
  // State hooks for managing user data, messages, form data, and active tab
  const [user, setUser] = useState(null);
  const [message, setMessage] = useState('');
  const [formData, setFormData] = useState({ name: '', email: '' });
  const [activeTab, setActiveTab] = useState('profile');
  const navigate = useNavigate(); // Hook for programmatic navigation

  // Effect hook to load user data on component mount
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user')); // Get user data from local storage
    if (userData) {
      setUser(userData); // Set user state
      setFormData({ userId: userData.id, name: userData.name, email: userData.email }); // Set form data
      console.log(userData.id); // Log user ID for debugging
    } else {
      setMessage('User not found. Please log in.'); // Set error message if user data is not found
      navigate('/login'); // Redirect to login page if user data is missing
    }
  }, [navigate]);

  // Handler for updating form data based on input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Define the function to handle profile updates
  const handleUpdateProfile = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    
    // Retrieve the authentication token from local storage
    const token = localStorage.getItem('auth_token');
    
    // Assume userId is obtained from local storage or state
    const userId = JSON.parse(localStorage.getItem('user')).id;

    // Make the API request to update the user profile
    axios.post(`https://phplaravel-1325195-4846320.cloudwaysapps.com/api/profile/${userId}/edited`, formData, {
        headers: {
            'Authorization': `Bearer ${token}`, // Include the authentication token
            'Content-Type': 'application/json',
        }
    })
    .then(response => {
      // If the profile update was successful
      if (response.status === 200) {
          // Clear user state and remove user data from local storage
          setUser(null);
          localStorage.removeItem('user');
          
          // Redirect to the logout route or homepage
          window.location.href = '/logout';  // Redirect to logout or homepage after logging out
      } else {
          setMessage('Profile updated successfully, but unable to log out.');
      }
    })
    .catch(error => {
      console.error('Error updating profile:', error); // Log error details
      setMessage('There was an error updating your profile.'); // Set error message
    });
  };

  // Function to handle navigation to the Add Product page
  const handleAddProduct = () => {
    navigate('/createProduct');
  };

  return (
    <div className="container mt-5">
      <style>
        {`
          .hero-image img {
            width: 100%;
            height: auto;
            border-radius: 10px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          }
          .form-group label {
            color: white;
          }
          .form-control {
            color: white;
            background-color: #343a40;
            border-color: #495057;
          }
          .btn-primary {
            background-color: #007bff;
            border-color: #007bff;
          }
          .btn-primary:hover {
            background-color: #0056b3;
            border-color: #004085;
          }
          .nav-link.active {
            background-color: #007bff;
            color: white;
          }
          .tab-pane {
            color: white;
          }
        `}
      </style>
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10 col-xl-8 mx-auto">
          <div className="my-4">
            <ul className="nav nav-tabs mb-4" id="myTab" role="tablist">
              {/* Navigation tabs */}
              <li className="nav-item">
                <a
                  className={`nav-link ${activeTab === 'profile' ? 'active' : ''}`}
                  id="profile-tab"
                  data-bs-toggle="tab"
                  href="#profile"
                  role="tab"
                  aria-controls="profile"
                  aria-selected={activeTab === 'profile'}
                  onClick={() => setActiveTab('profile')}
                >
                  Profile
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${activeTab === 'update-profile' ? 'active' : ''}`}
                  id="update-profile-tab"
                  data-bs-toggle="tab"
                  href="#update-profile"
                  role="tab"
                  aria-controls="update-profile"
                  aria-selected={activeTab === 'update-profile'}
                  onClick={() => setActiveTab('update-profile')}
                >
                  Update Profile
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${activeTab === 'add-product' ? 'active' : ''}`}
                  id="add-product-tab"
                  data-bs-toggle="tab"
                  href="#add-product"
                  role="tab"
                  aria-controls="add-product"
                  aria-selected={activeTab === 'add-product'}
                  onClick={() => handleAddProduct()}
                >
                  Add Product
                </a>
              </li>
            </ul>
            <div className="tab-content">

              {/* Profile Tab */}
              <div
                className={`tab-pane fade ${activeTab === 'profile' ? 'show active' : ''}`}
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <h4 className="mb-1">Welcome {formData.name}</h4>
              </div>

              {/* Update Profile Tab */}
              <div
                className={`tab-pane fade ${activeTab === 'update-profile' ? 'show active' : ''}`}
                id="update-profile"
                role="tabpanel"
                aria-labelledby="update-profile-tab"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-sm-8">
                      {/* Hero Image */}
                      <div className="hero-image">
                        <img
                          src={heroImage} // Using the imported heroImage variable
                          className="img-fluid rounded shadow"
                          alt="Hero Image"
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      {/* Form for updating profile */}
                      <form onSubmit={handleUpdateProfile}>
                        <div className="form-group">
                          <label htmlFor="name">Name</label>
                          <input
                            type="text"
                            id="name"
                            className="form-control"
                            placeholder="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="email">Email</label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </div>
                        <button type="submit" className="btn btn-primary mt-3">Save Changes</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              {/* Update Password Tab */}
              <div
                className={`tab-pane fade ${activeTab === 'update-password' ? 'show active' : ''}`}
                id="update-password"
                role="tabpanel"
                aria-labelledby="update-password-tab"
              >
                <div className="hero-image">
                  <div className="row">
                    <div className="col-sm-8">
                      <div className="hero-image">
                        <img
                          src={heroImage} // Using the imported heroImage variable
                          className="img-fluid rounded shadow"
                          alt="Hero Image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <ProductList /> {/* Render the ProductList component */}
    </div>
  );
}

export default Dashboard;

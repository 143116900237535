import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS for styling
import { Link } from 'react-router-dom'; // Import Link component for navigation

function Navbar() {
  return (
    <div>
      {/* Internal CSS styles for the Navbar component */}
      <style>
        {`
          /* Custom navbar styles */
          .navbar-custom {
            background-color: #343a40; /* Dark background for the navbar */
          }

          /* Branding style */
          .navbar-brand {
            font-weight: bold;
            font-size: 1.5rem; /* Larger font size for the brand name */
          }

          /* Navbar link styles */
          .nav-link {
            color: #f8f9fa; /* Light color for navbar links */
            font-weight: 500; /* Medium font weight for the links */
          }

          /* Navbar link hover effect */
          .nav-link:hover {
            color: #e9ecef; /* Slightly lighter color on hover */
          }
        `}
      </style>

      {/* Navbar structure */}
      <nav className="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top">
        <div className="container">
          {/* Brand name with link to home page */}
          <Link className="navbar-brand" to="/">CUPPACLICK</Link>

          {/* Toggle button for collapsing navbar on smaller screens */}
          <button 
            className="navbar-toggler" 
            type="button" 
            data-bs-toggle="collapse" 
            data-bs-target="#navbarNav" 
            aria-controls="navbarNav" 
            aria-expanded="false" 
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Navbar links */}
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              {/* Home link */}
              <li className="nav-item">
                <Link className="nav-link" to="/">Home</Link>
              </li>

              {/* Register link */}
              <li className="nav-item">
                <Link className="nav-link" to="/register">Register</Link>
              </li>

              {/* Login link */}
              <li className="nav-item">
                <Link className="nav-link" to="/login">Login</Link>
              </li>

              {/* Logout link */}
              <li className="nav-item">
                <Link className="nav-link" to="/logout">Logout</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;

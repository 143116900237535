import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Import Link for navigation
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import heroImage from './images/pp6_hero.jpg'; // Import the hero image
import axios from 'axios'; // Import Axios for HTTP requests

function Register() {
  const [name, setName] = useState(''); // State to store the name input
  const [email, setEmail] = useState(''); // State to store the email input
  const [password, setPassword] = useState(''); // State to store the password input
  const [passwordConfirmation, setPasswordConfirmation] = useState(''); // State to store password confirmation
  const [message, setMessage] = useState(''); // State to store feedback messages
  const navigate = useNavigate(); // Hook for navigation

  // Internal CSS styles
  const styles = {
    container: {
      marginTop: '5rem',
    },
    heroImage: {
      width: '100%',
      borderRadius: '0.25rem',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    form: {
      backgroundColor: '#343a40',
      padding: '2rem',
      borderRadius: '0.25rem',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      color: '#ffffff',
    },
    button: {
      backgroundColor: '#f8f9fa',
      color: '#343a40',
      marginTop: '1rem',
    },
    link: {
      color: '#17a2b8',
    },
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate passwords match
    if (password !== passwordConfirmation) {
      setMessage('Passwords do not match.');
      return;
    }

    try {
      // Send registration request
      const response = await axios.post('https://phplaravel-1325195-4846320.cloudwaysapps.com/api/register', {
        name,
        email,
        password,
        password_confirmation: passwordConfirmation,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });

      // Handle successful registration
      if (response.data.success) {
        setMessage('Registration successful! Redirecting...');
        setTimeout(() => navigate('/login'), 2000); // Redirect to login page after 2 seconds
      } else {
        setMessage(response.data.message || 'Registration failed. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage(`An error occurred: ${error.response?.data?.message || error.message}`);
    }
  };

  return (
    <div style={styles.container}>
      <div className="row">
        {/* Hero Image Column */}
        <div className="col-sm-8">
          <div style={styles.heroImage}>
            <img
              src={heroImage} // Use the imported heroImage variable
              className="img-fluid rounded shadow"
              alt="Hero Image"
            />
          </div>
        </div>

        {/* Register Form Column */}
        <div className="col-sm-4">
          <h1 className="text-white">Register</h1>
          <form onSubmit={handleSubmit} style={styles.form}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                className="form-control"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password_confirmation">Confirm Password</label>
              <input
                type="password"
                className="form-control"
                id="password_confirmation"
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn" style={styles.button}>Register</button>
            {message && <p className="mt-2">{message}</p>}
            <p className="text-white mt-3">
              Already registered? <Link to="/login" style={styles.link}>Login here</Link>
            </p> {/* Link to login page */}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Register;
